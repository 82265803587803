body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import "colors.css";

/*

font-family: 'Montserrat', sans-serif;

font-family: 'Raleway', sans-serif;

font-family: 'Poppins', sans-serif;

font-family: 'Noto Sans JP', sans-serif;

font-family: 'Quicksand', sans-serif;
*/

/* font-family: 'Rokkitt', serif; */

/* font-family: 'Enriqueta', serif; */


  /* font-family: 'Suez One', serif; */

h1 {
  font-family: 'Enriqueta', serif;
  font-size: 45px;
  color: var(--blue);
  margin-right: 5vw;
}

.menu-link {
  font-family: 'Rokkitt', serif;
  
}

p {
  line-height: 1.5em;
  font-size: 20px;
}

ul {
  /* line-height: 2em; */
  font-size: 20px
}

ol {
  /* line-height: 2em; */
  font-size: 20px
}

li {
  padding-bottom: 20px;
}

.specialLi {
  padding: 20px 0px

}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-right: 15px; */
}

.header-tan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-right: 15px; */
  background-color: white;
}

.nav {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  padding-top: 15px;
  margin: 0;
}

.nav-link-tan {
  padding: 8px;
  /* background-color: black; */
  border: 2px solid var(--blue);
  text-align: right;
  /* margin: 2px 0; */
  font-size: 1.7vw;
  text-decoration: none;
  list-style-type: none;
  margin-right: 7px;
  background-color: var(--blue);
  

}

.nav-link-tan:hover {
  background-color: var(--sand);
  border: 2px solid var(--sand);
  /* padding-left: 50px; */
}

.underline-tan {
  text-decoration: none;
  color: var(--sand);
}

.underline-tan:hover {
  text-decoration: none;
  color: var(--blue);
}

.ham-link-tan {
  padding: 10px;
  list-style-type: none;
  font-size: 6vw;
  background-color: var(--blue);
  border: 2px solid var(--blue);
  margin-bottom: 8px;
}

.ham-link-tan:hover {
  background-color: var(--sand);
  /* padding-left: 50px; */
}

.ham-link {
  padding: 10px;
  list-style-type: none;
  font-size: 6vw;
  background-color: white;
  border: 2px solid var(--blue);
  margin-bottom: 8px;
}

.ham-link:hover {
  background-color: var(--sand);
  /* padding-left: 50px; */
}

.nav-link {
  padding: 8px;
  /* background-color: black; */
  border: 2px solid var(--blue);
  text-align: right;
  /* margin: 2px 0; */
  font-size: 1.7vw;
  text-decoration: none;
  list-style-type: none;
  margin-right: 7px;

}

.underline {
  text-decoration: none;
  color: var(--blue);
}

.nav-link:hover {
  background-color: var(--sand);
  border: 2px solid var(--sand);
  /* padding: 2px; */
  /* padding-left: 50px; */
}

.burger-icon {
  position: fixed;
  padding: 15px;
  right: 0;
  top: 0;
}

.hamburgerDropDown {
  position: fixed;
  left: 5vw;
  top: 15px;
  width: 60vw;
  margin: 0;
  padding-left: 0;
  z-index: 1;
}











.page-container {
  /* padding-left: 5vw; */
  padding-bottom: 50px;
  
}
@media (max-width: 800px) {
  .we-p {
    line-height: 40px;
    font-size: 32px;
    font-family: 'Enriqueta', serif;
    color: var(--blue);

  
    margin: 30px 8vw 0;
   
  
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .we-p {
    line-height: 5vw;
    font-size: 4vw;
    font-family: 'Enriqueta', serif;
    color: var(--blue);

  
    margin: 30px 8vw 0;
   
  
  }
}
@media (max-width: 1000px) {

  .who-we-are {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .w-container {
    width: 100%;
    margin: auto;
  }
  .p-container {
    margin: auto;
    padding: 50px;
  }
  .bottom {
    margin-bottom: 40px;
  }
  .top {
    margin-top: 40px;
  }
  .we-are {
    margin: 30px 8vw;
    line-height: 24px;
  }
}
@media (min-width: 1000px) {
  .we-p {
    line-height: 5vw;
    font-size: 4vw;
    font-family: 'Enriqueta', serif;
    color: var(--blue);
    padding-right: 5vw;
  
    margin: 30px 0px 20px 50px;
    /*line-height: 24px;
  
    margin: 30px 12vw;
    line-height: 24px; */
  
  }

.who-we-are {
  display: flex;
  flex-direction: row;
  margin: 40px 0;
}
.w1-container {
  width: 50%;
  margin: auto;
}
.w2-container {
  width: 50%;
  margin: auto;
} 
.p-container {
  width: 50%;
  margin: 50px;
}
.we-are {
  margin: 10px 0px 20px 50px;
  line-height: 24px;
}
}


.group-photo {
  display: block;
  width: 80%;
  margin: auto;
  border: 5px solid var(--sand);
  }


  .lecture-photo {
    width: 80%;
    display: block;
    margin: auto;
    /* margin: 5% 10%; */
    border: 5px solid var(--blue);
   }

  .programs {
    margin-bottom: 15px;
    margin-top: 0; 
  }

  /* .larger {
    margin: 20px 0;
  } */





.h1-span {
  font-family: 'Enriqueta', serif;
  font-size: 45px;
  color: var(--blue);
  padding-right: 0.2em;
}

.mission {
  background-color: var(--blue);
  padding: 30px 0 15px;
  margin-bottom: 40px;
}

.no-pad {
  padding-top: 0;
  margin-top: 0;
}

.white {
  color: white
}

.p {
  margin-right: 5vw;
}

.indent {
  padding-left: 5vw;
}

.indent-more {
  margin-left: 2vw;
}

.indent-double {
  margin-left: 4vw;
}


  

  




.logo {
  padding: 20px;
}
.logo-width {
  width: 25vw;
  min-width: 145px;
}

























/* .quote {
  height: 8vw;
  font-size: 20vw;
}

.text-test {
  font-size: 1.2em;
} */






/* BLCKQUOTE TEST */
@media (max-width: 900px) {
.test-container {
  padding: 30px 0;
  background-color: var(--blue);
  border-bottom: 10px solid var(--sand);
}
/* .quote-text {
  font-size: 3vw;
} */
footer{
  margin:0;
  text-align: right;
  font-size: 2.25vw;
  font-style: italic;
  color: white;
}

.quotation{
  font-size: 3vw;
  color: white;
  margin: 0 auto;
  quotes: "\201C""\201D""\2018""\2019";
  padding: 10px 20px;
  line-height: 1.4;
}
}

@media (min-width: 850px) {
  .test-container {
    padding: 30px 6vw;
    background-color: var(--blue);
    border-bottom: 10px solid var(--sand);
  }
  /* .quote-text {
    font-size: 27px;
  } */
  footer{
    margin:0;
    text-align: right;
    font-size: 18px;
    font-style: italic;
    color: white;
  }

  .quotation{
    font-size: 22px;
    color: white;
    width: 75vw;
    margin: 0 auto;
    quotes: "\201C""\201D""\2018""\2019";
    padding: 10px 20px 30px;
    line-height: 1.4;
  }
  }

/* .quotation:before {
  content: open-quote;
  display: inline;
  height: 0;
  line-height: 0;
  left: -10px;
  position: relative;
  top: 30px;
  color: #ccc;
  font-size: 3em;
}
.quotation::after {
  content: close-quote;
  display: inline;
  height: 0;
  line-height: 0;
  left: 10px;
  position: relative;
  top: 35px;
  color: #ccc;
  font-size: 3em;
} */

/* BLCKQUOTE TEST */











.a-team {
  /* background-color: aqua; */
  /* height: 250px; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
@media (max-width: 980px) {
.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  /* padding-bottom: 70px; */
  width: 225px;
  text-align: center;
  /* background-color: var(--sand); */
  border-radius: 50px;
}
}
@media (max-width: 1225px) and (min-width: 980px) {
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    /* padding-bottom: 70px; */
    width: 300px;
    text-align: center;
    /* background-color: var(--sand); */
    border-radius: 50px;
  }
  }
@media (min-width: 1225px) {
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    /* padding-bottom: 70px; */
    width: 320px;
    text-align: center;
    /* background-color: var(--sand); */
    border-radius: 50px;
  }
  }


.card-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 10px solid var(--sand)
}

.card-img:hover {
  border: 5px solid var(--blue);
  margin: 5px;
}

.card-text {
  margin: 1px;
  font-size: 18px;
  /* color: white; */
}

.a-name {
  margin: 1px;
  margin-top: 10px;
  font-size: 1.7em;
}
/* ^A-CARD */




.button {
  background-color: var(--blue);
  height: 100px;
  width: 250px;
  /* display: table-cell; */
  line-height: 100px;
  font-size: 24px;
  text-decoration: none;
  color: white;
  text-align: center;
  margin: 50px auto;
  /* border: 2px solid var(--sand) */
}

a {
  text-decoration: none;
}


.button:hover {
  margin-top: 55px;
}










/* .m-card-container {
  background-color: white;
  margin: 14px 8px;
  padding: 10px 30px;
   border: 2px dashed black; 
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
} */

.member-heading {
  text-align: center;
  margin-right: 0;
  padding-bottom: 20px;
}

.m-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  /* padding: 10px 0; */
  /* margin: auto; */
}

.m-card-container {
  position: relative;
  /* width: 50%; */
}

@media (min-width: 1250px) {
.m-card-image {
  display: block;
  width: 20vw;
  /* height: auto; */
  height: 24vw;
  object-fit: cover;
}
}
@media (min-width: 900px) and (max-width: 1250px) {
  .m-card-image {
    display: block;
    width: 25vw;
    /* height: auto; */
    height: 30vw;
    object-fit: cover;
  }
  }

  @media (min-width: 650px) and (max-width: 900px) {
    .m-card-image {
      display: block;
      width: 33.3vw;
      /* height: auto; */
      height: 40vw;
      object-fit: cover;
    }
    }

    @media (max-width: 650px) {
      .m-card-image {
        display: block;
        width: 50vw;
        /* height: auto; */
        height: 60vw;
        object-fit: cover;
      }
      }

.m-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: .5s ease;
  background-color: rgba(13, 25, 163, .4);
  /* text-align: center; */
}

.blue {
  background-color: var(--blue);
}

.m-card-container:hover .m-overlay {
  opacity: 0.8;
}

.m-text {
  color: white;
  padding-left: 10px;
  margin: 2px;
  
}

@media (min-width: 450px) {
.m-name {
  /* padding-top: 20px; */
  font-size: 1.8em
}

}

@media (max-width: 450px) {
  .m-name {
    /* padding-top: 20px; */
    font-size: 6vw
  }
  .m-other {
    font-size: 4vw
  }
}

.m-textbox {
  position: absolute;
  bottom: 10px;
  width: 100%;
}


.landing-container {
  background-color: white;
  /* height: 100vh; */
}

 .vert-center {
 background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* margin: auto; */
}

.l-t-c {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


.landing-text {
  font-size: 7vw;
  padding-left: 5vw;
  margin: 0;
  line-height: 1.5em;
  
  /* text-align: center; */

font-family: 'Ultra', serif;
  color: var(--blue);
 
}

/* @media (max-width: 750px ){
  .landing-image {
    width: 100vw;
    height: 50vh;
    object-fit: cover;
  }


  .landing-text {
    z-index: 2;
    font-size: 3em;
    padding-left: 5vw;
    margin-right: 5vw;
    margin-top: 0;
    margin-bottom: 10px;
  font-family: 'Ultra', serif;
    color: var(--blue);
  }
} */


















.sand-background {
  width: 100%;
  background-color: var(--sand);
  padding: 50px 0 75px 0;
}

.about-text-link {
  color: var(--blue)
}

.about-text-link:visited {
  color: var(--blue)
}


.tan-foot {
  padding: 20px;
  background-color: var(--sand);
}

@media (max-width: 600px) {
  .tan-text {
    font-size: 20px;
    width: 80vw;
    margin: auto;
    font-weight: 900;
  }

}
@media (min-width: 600px) {
.tan-text {
  font-size: 36px;
  width: 80vw;
  margin: auto;
  font-weight: 900;
}
}


.caption {
  width: 80vw;
  max-width: 750px;
  margin: 20px auto;
  text-align: center;
  color: var(--blue);
  font-size: 16px;

}